<template>
  <div class="home" style="height: 100%">
    <v-container style="height: 70%" align-center justify-center>
      <v-row>
        <v-col cols="3" />
        <v-col cols="6"
          ><strong>{{ $t("confirmation.title") }}</strong></v-col
        >
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <v-col v-if="$store.state.currentStep > 300" cols="6">{{
          $t("confirmation.messagePick")
        }}</v-col>
        <v-col v-else cols="6">{{ $t("confirmation.message") }}</v-col>
        <v-col cols="3" />
      </v-row>
      <v-row>
        <v-col cols="3" />
        <!-- eslint-disable vue/no-v-html -->
        <v-col v-if="$store.state.currentStep > 300" cols="6"
          ><p v-html="$t('confirmation.messagePickDetail')"
        /></v-col>
        <v-col v-else cols="6"
          ><p v-html="$t('confirmation.messageDetail')"
        /></v-col>
        <!-- eslint-enable -->
        <v-col cols="3" />
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Confirmation",
  mounted() {
    let cb_conf = this.$store.state.callback;
    console.log(cb_conf);
    if (!this.$store.state.has_motors) {
      console.log("setting timeout");
      window.setTimeout(() => {
        window.location.href = cb_conf;
      }, process.env.VUE_APP_NOMOTOR_END_TIMEOUT);
    }
  },
};
</script>
